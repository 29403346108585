import React, { useState } from "react";
import styled from "styled-components";
import LoginInputGroup from "./LoginInputGroup";
import AuthorizationButton from "../../shared/buttons/AuthorizationButton";
import { useAuth } from "../../../hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
import { loginAction } from "./actions/loginAction";
import InputErrorMessage from "../../shared/inputs/InputErrorMessage";

const Container = styled.form`
  position: relative;
  margin: auto;
  width: 90%;
`;

const InputErrorMessageWrapper = styled.div`
  margin-top: 30px;
`;

const Title = styled.h1`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-Black);
`;

const LinksContainer = styled.div`
  margin-top: 60px;
`;


const TextLink = styled.p`
  padding-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Black);
`;

const Link = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--color-Pink400);
`;

function LoginBody() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const onChangeEmail = (e: string) => {
    setEmail(e);
    setError(null);
  };

  const onChangePassword = (e: string) => {
    setPassword(e);
    setError(null);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    loginAction({ email, password })
      .then((res) => {
        const { headers } = res;
        setToken(headers["authorization"]);
        localStorage.setItem("token", headers["authorization"]);
        navigate("/");
      })
      .catch((err) => {
        setError(err.response.data.status.message);
      });
  };

  return (
    <Container onSubmit={(e) => onSubmit(e)}>
      <Title>Login</Title>
      <LoginInputGroup
        email={email}
        password={password}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        error={!!error}
      />
      <InputErrorMessageWrapper>
        <InputErrorMessage message={error} />
      </InputErrorMessageWrapper>
      <AuthorizationButton text="Sign in" />

      <LinksContainer>
        <TextLink>
          Don't Have An Account? <Link to="/sign-up">Sign up</Link>
        </TextLink>
        <TextLink>
          <Link to="/restore-password">Forgot Your Password?</Link>
        </TextLink>
      </LinksContainer>
    </Container>
  );
}

export default LoginBody;
