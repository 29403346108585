import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TransactionForm from "./TransactionForm";
import YearScrollBlock from "./YearScrollBlock";
import CardsList from "./CardsList";
import InputsProvider from "../../../context/HomeFormProvider";
import AccessPromotionCard from "./AccessPromotionCard";
import { useAuth } from "../../../hooks/useAuth";

const AccessPromotionCardContainer = styled.div`
  margin-bottom: 24px;
  padding: 0 8px;
`;

function HomeBody() {
  const { profile } = useAuth();
  const [buyPremiumLink, setBuyPremiumLink] = useState("");
  const [showAccessPromotionCard, setShowAccessPromotionCard] = useState(false);

  useEffect(() => {
    if (profile) {
      setShowAccessPromotionCard(!profile.premium.active);
      setBuyPremiumLink(
        `${process.env.REACT_APP_PREMIUM_BASE_URL}?checkout[custom][user_id]=${profile.id}`
      );
    }
  }, [profile]);

  return (
    <>
      {
        showAccessPromotionCard && (
          <AccessPromotionCardContainer>
            <AccessPromotionCard link={buyPremiumLink} />
          </AccessPromotionCardContainer>
        )
      }
      <InputsProvider>
        <TransactionForm />
      </InputsProvider>
      <YearScrollBlock />
      <CardsList />
    </>
  );
}

export default HomeBody;
