import React, { useState } from "react";
import styled from "styled-components";
import EyeSlash from "jsx:../../../assets/Eye_slash.svg";
import Eye from "jsx:../../../assets/Eye.svg";

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

const InputStyle = styled.input`
  width: 100%;
  margin: 1px 0;
  border: 1px solid var(--color-Gray);
  outline: none;
  border-radius: 30px;
  padding: 19px 48px 19px 20px;
  color: var(--color-Black);
  box-shadow: 0px 13px 25px 0px rgba(0, 0, 0, 0.03);
  &::placeholder {
    color: var(--color-Gray);
  }
  &:focus {
    margin: 0;
    border: 2px solid var(--color-Black);
  }
  &.error {
    margin: 0;
    border: 2px solid var(--color-Red);
  }
`;

const EyePassword = styled(EyeSlash)`
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
  cursor: pointer;
`;

const EyeText = styled(Eye)`
  position: absolute;
  top: calc(50% - 12px);
  right: 20px;
  cursor: pointer;
`;

interface Props {
  secure: boolean;
  placeholder: string;
  value: string;
  error: boolean;
  onChange: (e: string) => void;
}

function AuthorizationInput({
  secure,
  placeholder,
  value,
  onChange,
  error,
}: Props) {
  const [displayText, setDisplayText] = useState(secure ? false : true);

  return (
    <InputContainer>
      <InputStyle
        type={displayText ? "text" : "password"}
        placeholder={placeholder}
        value={value}
        className={error ? "error" : ""}
        onChange={(e) => onChange(e.target.value)}
      />
      {secure &&
        (displayText ? (
          <EyePassword onClick={() => setDisplayText(false)} />
        ) : (
          <EyeText onClick={() => setDisplayText(true)} />
        ))}
    </InputContainer>
  );
}

export default AuthorizationInput;
