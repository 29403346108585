import styled from "styled-components";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 24px;
  padding: 20px 16px;
  margin-bottom: 42px;
  border-radius: 25px;
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

export default Card;
