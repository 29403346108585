import React from "react";
import styled from "styled-components";
import TransactionInput from "../../shared/inputs/TransactionInput";
import TransactionDropdown from "./TransactionDropdown";
import { todayDate } from "../../../helpers/todayDate";
import { useDashboard } from "../../../hooks/useDashboard";
import { NumberFormatValues, NumericFormat } from "react-number-format";
import { useHomeForm } from "../../../hooks/useHomeForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const AmountRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  column-gap: 20px;
`;

const AmountInput = styled.div`
  position: relative;
  text-align: left;
  width: 60%;
`;

const AmountTitle = styled.h3`
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Black);
`;

const ValuteDropdown = styled.div`
  margin-right: 10px;
  width: 30%;
`;

const DateInput = styled.div`
  text-align: left;
  width: 50%;
`;

const DateTitle = styled.h3`
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Black);
`;

function InputGroup() {
  const { meta } = useDashboard();
  const { transactionForm, setTransactionForm, error, setError } =
    useHomeForm();

  const onChangeAmount = (e: NumberFormatValues) => {
    let num = Number(e.floatValue);
    setTransactionForm({ ...transactionForm, amount: num });
    setError({ value: "", isTrue: false });
  };

  const onValueChange = (value: string) => {
    setTransactionForm({ ...transactionForm, currency: value });
  };

  const onDateChange = (value: string) => {
    setTransactionForm({ ...transactionForm, date: value });
  };

  return (
    <Container>
      <AmountRow>
        <AmountInput>
          <AmountTitle>Amount</AmountTitle>
          <NumericFormat
            className={error.isTrue ? "error" : ""}
            customInput={TransactionInput}
            value={transactionForm.amount}
            decimalScale={2}
            onValueChange={(e) => onChangeAmount(e)}
          />
        </AmountInput>
        <ValuteDropdown>
          <TransactionDropdown
            value={transactionForm.currency}
            items={meta.availableCurrencies.map((item) => {
              return { key: item.code, name: item.name };
            })}
            onChange={onValueChange}
          />
        </ValuteDropdown>
      </AmountRow>
      <DateInput>
        <DateTitle>Date</DateTitle>
        <TransactionInput
          type="date"
          value={transactionForm.date}
          min="2016-01-01"
          max={todayDate(new Date())}
          onChange={(e) => onDateChange(e.target.value)}
        />
      </DateInput>
    </Container>
  );
}

export default InputGroup;
