import React from "react";
import SettingsBody from "./SettingsBody";
import MainLayout from "../../shared/layouts/MainLayout";

function SettingsPage() {
  return (
    <MainLayout>
      <SettingsBody />
    </MainLayout>
  );
}

export default SettingsPage;
