import React from "react";
import styled from "styled-components";
import RestorePasswordBody from "./RestorePasswordBody";

const Page = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: var(--color-White);
`;

function RestorePasswordPage() {
  return (
    <Page>
      <RestorePasswordBody />
    </Page>
  );
}

export default RestorePasswordPage;
