import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled(Link)`
  border-radius: 18px;
  background-color: #faa499;
  background-image: linear-gradient(
    319deg,
    #faa499 0%,
    #f7dd85 37%,
    #ffc55c 100%
  );
  color: var(--color-Black);

  margin-top: 2px;
  padding: 14px 0;
  cursor: pointer;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  display: block;
  text-decoration: none;
  :hover {
    margin-top: 0;
    margin-bottom: 2px;
  }
`;

const MainText = styled.span`
  font-size: 16px;
  font-weight: 300;
`;

const Price = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

interface Props {
  link: string;
}

function AccessPromotionCard({ link }: Props) {
  return (
    <Card to={link}>
      <MainText>🪐 Get full access just for</MainText>
      <Price>&nbsp;2.99ლ/month</Price>
    </Card>
  );
}

export default AccessPromotionCard;
