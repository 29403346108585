import React from "react";
import styled from "styled-components";
import Header from "../Header";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--color-White);
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  min-height: 100%;
  width: 100%;
`;

interface Props {
  children: React.ReactNode;
}

function MainLayout({ children }: Props) {
  return (
    <Page>
      <Header />
      <Body>
        {children}
      </Body>
    </Page>
  );
};

export default MainLayout;
