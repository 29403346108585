import styled from "styled-components";

const InputTip = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  color: var(--color-Gray);
`;


export default InputTip;
