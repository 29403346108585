import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TransactionInput from "../../shared/inputs/TransactionInput";
import Button from "../../shared/buttons/Button";
import { putCredentialsAction } from "./actions/putCredentialsAction";
import { useAuth } from "../../../hooks/useAuth";
import { getCredentialsAction } from "./actions/getCredentialsAction";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`;

const Row = styled.div`
  text-align: left;
`;

const Title = styled.h3`
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: var(--color-Black);
`;

function CredentialsForm() {
  const { token } = useAuth();
  const [credentialsForm, setCredentialsForm] = useState({
    login: "",
    password: "",
  });
  const [stateButton, setStateButton] = useState({
    text: "Save",
    class: "",
    disabled: false,
  });

  const onChangeLogin = (value: string) => {
    setCredentialsForm({ ...credentialsForm, login: value });
  };

  const onChangePassword = (value: string) => {
    setCredentialsForm({ ...credentialsForm, password: value });
  };

  useEffect(() => {
    if (token) {
      getCredentialsAction({ token })
        .then((res) => {
          setCredentialsForm({
            login: res.data.rsgeCredentials.login,
            password: res.data.rsgeCredentials.password,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const onSubmit = () => {
    if (token) {
      putCredentialsAction({
        token: token,
        login: credentialsForm.login,
        password: credentialsForm.password,
      }).then(() => {
        setStateButton({ text: "Saved", class: "saved", disabled: true });
      });
    }
    setTimeout(() => {
      setStateButton({ text: "Save", class: "", disabled: false });
    }, 3000);
  };

  return (
    <Container>
      <Row>
        <Title>Login</Title>
        <TransactionInput
          value={credentialsForm.login}
          type="text"
          placeholder="Login"
          onChange={(e) => onChangeLogin(e.target.value)}
        />
      </Row>
      <Row>
        <Title>Password</Title>
        <TransactionInput
          value={credentialsForm.password}
          type="password"
          placeholder="Password"
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </Row>
      <Button
        onClick={onSubmit}
        disabled={stateButton.disabled}
        text={stateButton.text}
        className={stateButton.class}
      />
    </Container>
  );
}

export default CredentialsForm;
