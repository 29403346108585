import styled from "styled-components";

const TransactionInput = styled.input`
  border: 1px solid var(--color-VeryLightGray);
  outline: none;
  background: none;
  border-radius: 5px;
  padding: 10px 12px;
  color: var(--color-Black);
  height: 42px;
  width: 100%;
  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: var(--color-VeryLightGray);
  }
  &:focus {
    border: 1px solid var(--color-Black);
  }
  &.error {
    border: 1px solid var(--color-Red);
  }
`;

export default TransactionInput;
