import axiosInstance from "../../../../helpers/axiosInstance";

interface Props {
  token: string;
}

export const getCredentialsAction = ({ token }: Props) => {
  return axiosInstance.get(
    "/rsge/credentials",

    {
      headers: {
        Authorization: token,
      },
    }
  );
};
