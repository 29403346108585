import React from "react";
import styled from "styled-components";
import CredentialsForm from "./CredentialsForm";
import Title from "../../shared/Title";

const Block = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px 16px;
  border-radius: 25px;
  box-shadow: 0px 13px 34px 0px rgba(0, 0, 0, 0.05);
`;

const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;

const Text = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-Gray);
  text-align: left;
`;

function CredentialsBlock() {
  return (
    <Block>
      <TitleWrapper>
        <Title>RS.GE credentials</Title>
      </TitleWrapper>
      <CredentialsForm />
      <Text>
        To submit a declaration, please provide your login and password.
      </Text>
    </Block>
  );
}

export default CredentialsBlock;
