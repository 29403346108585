import React, { useEffect, useState } from "react";
import styled from "styled-components";
import InputGroup from "./InputGroup";
import Button from "../../shared/buttons/Button";
import { saveTransactionAction } from "./action/saveTransactionAction";
import { useAuth } from "../../../hooks/useAuth";
import { useDashboard } from "../../../hooks/useDashboard";
import { useHomeForm } from "../../../hooks/useHomeForm";

const Form = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  row-gap: 24px;
  padding: 20px 16px;
  margin-bottom: 42px;
  border-radius: 25px;
  box-shadow: 0px 13px 15px 0px rgba(0, 0, 0, 0.05);
  overflow: hidden;
`;

const DisabledOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--color-White);
  opacity: 0.5;
  z-index: 1;
  cursor: not-allowed;
  left: 0;
`;

function TransactionForm() {
  const { token, profile } = useAuth();
  const { setLastUpdateTime } = useDashboard();
  const { transactionForm, setTransactionForm, setError } = useHomeForm();

  const [disableOverlay, setDisableOverlay] = useState(false);

  const [stateButton, setStateButton] = useState({
    text: "Save",
    class: "",
    disabled: false,
  });

  useEffect(() => {
    setDisableOverlay(!profile?.premium.active);
  }, [profile]);

  const saveTransaction = () => {
    if (token) {
      saveTransactionAction(transactionForm)
        .then(() => {
          setLastUpdateTime(Date.now());
          setTransactionForm({ ...transactionForm, amount: 0.0 });
          setStateButton({ text: "Saved", class: "saved", disabled: true });
          setTimeout(() => {
            setStateButton({ text: "Save", class: "", disabled: false });
          }, 1000);
        })
        .catch((err) => {
          setError({
            value: err.response.data.messages.amount_cents[0],
            isTrue: true,
          })
        });
    }
  };
  return (
    <Form>
      { disableOverlay && <DisabledOverlay /> }
      <InputGroup />
      <Button
        text={stateButton.text}
        className={stateButton.class}
        disabled={stateButton.disabled}
        onClick={saveTransaction}
      />
    </Form>
  );
}

export default TransactionForm;
