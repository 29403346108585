import axiosInstance from "../../../../helpers/axiosInstance";

interface Props {
  token: string;
  login: string;
  password: string;
}

export const putCredentialsAction = ({ token, login, password }: Props) => {
  return axiosInstance.put("/rsge/credentials",
    {
      login: login,
      password: password,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  );
};
