import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import styled from "styled-components";
import SignUpPage from "./components/pages/signUp/SignUpPage";
import LoginPage from "./components/pages/login/LoginPage";
import HomePage from "./components/pages/home/HomePage";
import { useAuth } from "./hooks/useAuth";
import AuthProvider from "./context/AuthProvider";
import DashboardProvider from "./context/DashboardProvider";
import SettingsPage from "./components/pages/settigs/SettingsPage";
import RestorePasswordPage from "./components/pages/restorePassword/RestorePasswordPage";
import { AxiosInterceptor } from "./helpers/axiosInstance";

import { Toaster } from "react-hot-toast";
import ChangePasswordPage from "./components/pages/ChangePasswordPage";
import NotificationsPage from "./components/pages/notifications/NotificationsPage";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 500px;
  height: 100%;
`;

const ProtectedHomeRoute = ({ children }: any) => {
  const { token } = useAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const ProtectedAuthorizationRoute = ({ children }: any) => {
  const { token } = useAuth();

  if (token) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  return (
    <AuthProvider>
      <AxiosInterceptor>
        <BrowserRouter>
          <Container>
            <Toaster />
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedHomeRoute>
                    <DashboardProvider>
                      <HomePage />
                    </DashboardProvider>
                  </ProtectedHomeRoute>
                }
              />
              <Route
                path="sign-up"
                element={
                  <ProtectedAuthorizationRoute>
                    <SignUpPage />
                  </ProtectedAuthorizationRoute>
                }
              />
              <Route
                path="login"
                element={
                  <ProtectedAuthorizationRoute>
                    <LoginPage />
                  </ProtectedAuthorizationRoute>
                }
              />
              <Route
                path="settings"
                element={
                  <ProtectedHomeRoute>
                    <SettingsPage />
                  </ProtectedHomeRoute>
                }
              />
              <Route
                path="notifications"
                element={
                  <ProtectedHomeRoute>
                    <NotificationsPage />
                  </ProtectedHomeRoute>
                }
              />
              <Route
                path="restore-password"
                element={
                  <ProtectedAuthorizationRoute>
                    <RestorePasswordPage />
                  </ProtectedAuthorizationRoute>
                }
              />
              <Route
                path="change-password"
                element={
                  <ProtectedAuthorizationRoute>
                    <ChangePasswordPage />
                  </ProtectedAuthorizationRoute>
                }
              />
            </Routes>
          </Container>
        </BrowserRouter>
      </AxiosInterceptor>
    </AuthProvider>
  );
}

export default App;
