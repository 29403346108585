import React from "react";
import styled from "styled-components";

const Message = styled.p`
  width: 100%;
  text-align: center;
  color: var(--color-Red);
`;

interface Props {
  message: string | null;
}

function InputErrorMessage({ message }: Props) {
  return <Message>{message ? message : "\u00A0"}</Message>;
}

export default InputErrorMessage;
