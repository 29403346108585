import axios from "axios";
import { useLayoutEffect } from "react";
import { useAuth } from "../hooks/useAuth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

const AxiosInterceptor = ({ children }: any) => {
  const { removeToken, token } = useAuth();

  useLayoutEffect(() => {
    const reqInterceptor = axiosInstance.interceptors.request.use((config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    const resInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          removeToken();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosInstance.interceptors.request.eject(reqInterceptor);
      axiosInstance.interceptors.response.eject(resInterceptor);
    };
  }, [token, removeToken]);
  return children;
};

export default axiosInstance;
export { AxiosInterceptor };
