import React, { createContext, useState } from "react";

interface Meta {
  availableCurrencies: Currency[];
}

type DashboardContextType = {
  months: Month[];
  selectedYear: number;
  years: number[];
  meta: Meta;
  lastUpdateTime: number;
  setSelectedYear: (value: number) => void;
  setYears: (value: number[]) => void;
  setLastUpdateTime: (value: number) => void;
  setMonths: (value: Month[]) => void;
  setMeta: (value: Meta) => void;
};

export const DashboardContext = createContext<DashboardContextType>({
  months: [],
  selectedYear: new Date().getFullYear(),
  years: [],
  meta: {
    availableCurrencies: [],
  },
  lastUpdateTime: 0,

  setSelectedYear: () => {},
  setYears: () => {},
  setLastUpdateTime: () => {},
  setMonths: () => {},
  setMeta: () => {},
});

function DashboardProvider({ children }: any) {
  const [lastUpdateTime, setLastUpdateTime] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState<number[]>([]);
  const [months, setMonths] = useState<Month[]>([]);
  const [meta, setMeta] = useState<Meta>({
    availableCurrencies: [],
  });

  return (
    <DashboardContext.Provider
      value={{
        selectedYear,
        setSelectedYear,
        years,
        setYears,
        lastUpdateTime,
        setLastUpdateTime,
        months,
        setMonths,
        meta,
        setMeta,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export default DashboardProvider;
