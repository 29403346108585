import React, { createContext, useState } from "react";
import { todayDate } from "../helpers/todayDate";

interface Error {
  value: string;
  isTrue: boolean;
}

type HomeFormContextType = {
  transactionForm: DashboardTransactionForm;
  error: Error;
  setTransactionForm: (value: DashboardTransactionForm) => void;
  setError: (value: Error) => void;
};

export const HomeFormContext = createContext<HomeFormContextType>({
  transactionForm: {
    amount: 0.0,
    currency: "EUR",
    date: todayDate(new Date()),
  },
  error: { value: "", isTrue: false },
  setTransactionForm: () => {},
  setError: () => {},
});

function HomeFormProvider({ children }: any) {
  const [transactionForm, setTransactionForm] = useState({
    amount: 0.0,
    currency: "EUR",
    date: todayDate(new Date()),
  });

  const [error, setError] = useState({ value: "", isTrue: false });

  return (
    <HomeFormContext.Provider
      value={{ error, transactionForm, setTransactionForm, setError }}
    >
      {children}
    </HomeFormContext.Provider>
  );
}

export default HomeFormProvider;
