import axiosInstance from "../../../../helpers/axiosInstance";

interface Props {
  email: string;
  password: string;
}

export const loginAction = ({ email, password }: Props) => {
  return axiosInstance.post("/login", {
    email: email,
    password: password,
  });
};
