import React from "react";
import styled from "styled-components";

const StyledButton = styled.button<StyledProps>`
  display: ${(props) => props.display || "inline-block"};
  align-self: center;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  color: var(--color-White);
  background-color: var(--color-Black);
  -webkit-tap-highlight-color: transparent;
  transition-property: background-color;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  &.danger {
    background-color: var(--color-Red);
  }

  &:active {
    margin-top: 4px;
    margin-bottom: -4px;
  }
  &.saved {
    background-color: var(--color-Green);
    &:active {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

type StyledProps = {
  display?: string;
};

interface Props {
  onClick: () => void;
  text: string;
  display?: string;
  className?: string;
  disabled?: boolean;
}

function Button({ onClick, text, display, className, disabled }: Props) {
  return (
    <StyledButton
      className={className}
      display={display}
      disabled={disabled}
      onClick={() => onClick()}
    >
      {text}
    </StyledButton>
  );
}

export default Button;
