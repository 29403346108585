import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import Scroll from "jsx:../../../assets/Scroll.svg";
import { useDashboard } from "../../../hooks/useDashboard";

const lastYear = (ind: number) => keyframes`
  0% { left:  ${(ind + 1) * -80}px }
  100% {left:  ${ind * -80}px }
`;

const nextYear = (ind: number) => keyframes`
    0% { left:  ${(ind - 1) * -80}px }
  100% {left:  ${ind * -80}px }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 34px;
  align-items: center;
  justify-content: space-between;
`;

const YearsContainer = styled.div`
  position: relative;
  width: 80px;
  height: 40px;
  column-gap: 8px;
  overflow: hidden;
`;

const YearsBlock = styled.div<YearsProps>`
  display: flex;
  position: absolute;
  left: ${(props) => props.ind * -80}px;

  /* &.left {
    animation: ${(props) => lastYear(props.ind)} 0.3s ease-out;
  }
  &.right {
    animation: ${(props) => nextYear(props.ind)} 0.3s ease-out;
  } */
`;

const YearBlock = styled.div`
  display: flex;
  justify-content: center;
`;

const Year = styled.h1`
  width: 80px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-Black);
`;

const ScrollLeftButton = styled.button<ButtonProps>`
  display: flex;
  visibility: ${(props) => props.visibility};
  margin-left: 22px;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
`;

const ScrollRightButton = styled.button<ButtonProps>`
  display: flex;
  visibility: ${(props) => props.visibility};
  margin-right: 22px;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(180deg);
`;

type YearsProps = {
  ind: number;
};

type ButtonProps = {
  visibility: string;
};

function YearScrollBlock() {
  const { years, selectedYear, setSelectedYear } = useDashboard();

  const [scrollClass, setScrollClass] = useState("");

  const year = () => {
    if (years.length == 0) {
      return 0;
    }

    if (years[years.indexOf(selectedYear)] == selectedYear) {
      return years.indexOf(selectedYear);
    }
    if (years[years.indexOf(selectedYear) - 1] == undefined) {
      setSelectedYear(years[years.indexOf(selectedYear) + 1]);
      return years.indexOf(selectedYear) + 1;
    } else {
      setSelectedYear(years[years.indexOf(selectedYear) - 1]);
      return years.indexOf(selectedYear) - 1;
    }
  };

  const lastYear = () => {
    return years.indexOf(selectedYear) == years.length - 1;
  };

  const onClick = (name: string) => {
    if (name == "Left") {
      setSelectedYear(years[years.indexOf(selectedYear) - 1]);
      setScrollClass("left");
    } else {
      setSelectedYear(years[years.indexOf(selectedYear) + 1]);
      setScrollClass("right");
    }
  };

  return (
    <Container>
      <ScrollLeftButton
        onClick={() => onClick("Left")}
        visibility={
          years[years.indexOf(selectedYear) - 1] == undefined
            ? "hidden"
            : "visible"
        }
      >
        <Scroll />
      </ScrollLeftButton>
      <YearsContainer>
        <YearsBlock className={scrollClass} ind={year()}>
          {years.map((year) => {
            return (
              <YearBlock key={year}>
                <Year>{String(year)}</Year>
              </YearBlock>
            );
          })}
        </YearsBlock>
      </YearsContainer>
      <ScrollRightButton
        onClick={() => onClick("Right")}
        visibility={lastYear() ? "hidden" : " visible"}
      >
        <Scroll />
      </ScrollRightButton>
    </Container>
  );
}

export default YearScrollBlock;
