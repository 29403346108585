import React, { useState } from "react";
import styled from "styled-components";
import AuthorizationInput from "../shared/inputs/AuthorizationInput";
import AuthorizationButton from "../shared/buttons/AuthorizationButton";
import axiosInstance from "../../helpers/axiosInstance";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import InputErrorMessage from "../shared/inputs/InputErrorMessage";


const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: var(--color-White);
`;

const InputErrorMessageWrapper = styled.div`
  margin-top: 30px;
`;

const Body = styled.div`
  position: relative;
  margin: auto;
  width: 90%;
`;

const Title = styled.h1`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-Black);
`;

function ChangePasswordPage() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const { setToken } = useAuth();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  const onChangePassword = (e: string) => {
    setPassword(e);
    setError(null);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    axiosInstance
      .post("/update_password", {
        password: password,
        token: token,
      })
      .then((response) => {
        const { headers } = response;
        setToken(headers["authorization"]);
        localStorage.setItem("token", headers["authorization"]);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.status.message);
      });
  };

  return (
    <Page>
      <Body>
        <Title>Set new password</Title>

        <form onSubmit={(e) => onSubmit(e)}>
          <AuthorizationInput
            secure={true}
            placeholder="Enter new password"
            value={password}
            onChange={onChangePassword}
            error={!!error}
          />
          <InputErrorMessageWrapper>
            <InputErrorMessage message={error} />
          </InputErrorMessageWrapper>
          <AuthorizationButton text="Change Password" />
        </form>
      </Body>
    </Page>
  );
}

export default ChangePasswordPage;
