import React, { useState } from "react";
import MainLayout from "../../shared/layouts/MainLayout";
import Card from "../../shared/Card";
import Title from "../../shared/Title";
import styled from "styled-components";
import Button from "../../shared/buttons/Button";
import axiosInstance from "../../../helpers/axiosInstance";
import { NumericFormat } from "react-number-format";
import TransactionInput from "../../shared/inputs/TransactionInput";

const TitleWrapper = styled.div`
  margin-bottom: 24px;
`;


function NotificationsPage() {
  const defaultButtonState = { text: "Get Notifications", class: "", disabled: false };
  const [stateButton, setStateButton] = useState(defaultButtonState);

  const [displayOTP, setDisplayOTP] = useState(false);
  const [OTP, setOTP] = useState("");

  const getNotifications = () => {
    setStateButton({ text: "Loading...", class: "", disabled: true });

    axiosInstance.post("/rsge/notifications/fetch").then((response) => {
      setDisplayOTP(true);
      setStateButton(defaultButtonState);
    });
  };

  const closeOTPForm = () => {
    setDisplayOTP(false);
    setOTP("");
  }

  const sendOTP = () => {
    return axiosInstance.post("/rsge/otp_code", { code: OTP }).then((response) => {
      if (response.data.success) {
        console.log("OTP code is correct");
      } else {
        console.log("OTP code is incorrect");
      }
    });
  }

  return (
    <MainLayout>
      <Card>
        {displayOTP ? (
          <>
            <NumericFormat
              customInput={TransactionInput}
              value={OTP}
              decimalScale={0}
              placeholder="Write code from SMS"
              onValueChange={(e) => setOTP(e.value)}
            />

            <Button text="Send Code" onClick={() => { sendOTP() }} />
            <Button text="Cancel" onClick={() => {closeOTPForm()}} className="danger" />
          </>
        ) : (
          <>
            <TitleWrapper>
              <Title>Notifications</Title>
            </TitleWrapper>

            <Button
              onClick={getNotifications}
              disabled={stateButton.disabled}
              text={stateButton.text}
              className={stateButton.class}
            />
          </>
        )}
      </Card>
    </MainLayout>
  );
}

export default NotificationsPage;
