import React, { useState } from "react";
import styled from "styled-components";
import CheckSVG from "jsx:../../../assets/Check.svg";

const Container = styled.div`
  display: none;
  margin-top: 56px;
  width: 100%;
`;

const CheckboxLabel = styled.label`
  user-select: none;
  position: relative;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-LightGray);
  border-radius: 5px;
`;

const Checkbox = styled.input`
  height: 0;
  width: 20px;
  visibility: hidden;
  &:checked + span {
    visibility: inherit;
  }
`;

const CheckboxSpan = styled.span`
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
  background-color: var(--color-Pink200);
  border: 1px solid var(--color-Blue900);
  border-radius: 5px;
`;

const Check = styled(CheckSVG)`
  visibility: inherit;
  margin-bottom: 1px;
`;

const Text = styled.p`
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-Blue900);
`;

const Link = styled.a`
  color: var(--color-Pink400);
  text-decoration: none;
`;

function SignUpCheckbox() {
  const [checked, setChecked] = useState(false);

  const onChange = (checked: boolean) => {
    if (checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };
  return (
    <Container>
      <CheckboxLabel>
        <Checkbox
          type="checkbox"
          onChange={(e) => onChange(e.target.checked)}
          checked={checked}
        />
        <CheckboxSpan>
          <Check />
        </CheckboxSpan>
      </CheckboxLabel>
      <Text>
        I agree to the service and <Link href="">privacy policy </Link>
      </Text>
    </Container>
  );
}

export default SignUpCheckbox;
