import React from "react";
import CredentialsBlock from "./CredentialsBlock";

function SettingsBody() {
  return (
    <CredentialsBlock />
  );
}

export default SettingsBody;
