import React, { createContext, useEffect, useLayoutEffect, useState } from "react";
import axiosInstance from "../helpers/axiosInstance";


export type AuthContextType = {
  token: string | null;
  setToken: (token: string) => void;
  removeToken: () => void;
  profile: Profile | null;
  setProfile: (profile: Profile) => void;
};

export const AuthContext = createContext<AuthContextType>({
  token: null,
  setToken: () => {},
  removeToken: () => {},
  profile: null,
  setProfile: () => {},
});

function AuthProvider({ children }: any) {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [profile, setProfile] = useState<Profile | null>(null);

  useEffect(() => {
    if (token) {
      axiosInstance
        .get("/profile", { headers: { Authorization: token } })
        .then((response) => {
          setProfile(response.data);
        })
    }
  }, [token]);

  const removeToken = () => {
    localStorage.removeItem("token");
    setToken(null);
    setProfile(null);
    console.log(profile)
  };

  return (
    <AuthContext.Provider
      value={{
        token: token,
        setToken: setToken,
        removeToken: removeToken,
        profile: profile,
        setProfile: () => {},
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
