import React, { useEffect } from "react";
import HomeBody from "./HomeBody";
import { useAuth } from "../../../hooks/useAuth";
import { useDashboard } from "../../../hooks/useDashboard";
import axiosInstance from "../../../helpers/axiosInstance";
import MainLayout from "../../shared/layouts/MainLayout";

function HomePage() {
  const { token } = useAuth();
  const { setMeta } = useDashboard();

  useEffect(() => {
    if (token) {
      axiosInstance.get("/dashboard")
        .then((response) => {
          setMeta(response.data.meta);
        })
    }
  }, [token]);

  return (
    <MainLayout>
      <HomeBody />
    </MainLayout>
  );
}

export default HomePage;
