import React, { useEffect } from "react";
import TransactionCard from "./TransactionCard";
import { useDashboard } from "../../../hooks/useDashboard";
import { useAuth } from "../../../hooks/useAuth";
import axiosInstance from "../../../helpers/axiosInstance";

function CardsList() {
  const { token } = useAuth();
  const { lastUpdateTime, months, setMonths, setYears, selectedYear } =
    useDashboard();

  useEffect(() => {
    if (token) {
      axiosInstance.get("/transactions", { params: { year: selectedYear } })
                   .then((response) => {
                     setYears(response.data.meta.availableYears);
                     setMonths(response.data.months);
                   })
    }
  }, [lastUpdateTime, selectedYear]);

  return (
    <>
      {months.map((month, index) => {
        return (
          <TransactionCard key={month.month} month={month} index={index} />
        );
      })}
    </>
  );
}

export default CardsList;
