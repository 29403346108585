import React, { useState } from "react";
import styled from "styled-components";
import Transaction from "./Transaction";
import Button from "../../shared/buttons/Button";
import { useDashboard } from "../../../hooks/useDashboard";
import { useAuth } from "../../../hooks/useAuth";
import { Months } from "../../../constants/month";
import axiosInstance from "../../../helpers/axiosInstance";

import CopySVG from "jsx:../../../assets/Copy.svg";
import toast from "react-hot-toast";
import Card from "../../shared/Card";

const Title = styled.h2`
  padding-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
`;

const TransactionCardContainer = styled.div`
  padding: 8px;
`;

const TransactionsContainer = styled.div<StyleProps>`
  display: ${(props) => props.display};
  flex-direction: column;
  margin-bottom: 30px;
  row-gap: 18px;
`;

const TotalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 12px;
  border-top: 1px solid var(--color-Black);
`;
const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const Total = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  display: flex;
  align-items: center;
`;

const CopyButton = styled.button`
  display: flex;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  margin-left: 8px;
`;

const TotalAmount = styled.span`
`;

const CopyIcon = styled(CopySVG)`
  fill: var(--color-Gray);
  transition-duration: 0.1s;

  :hover {
    fill: var(--color-Black);
  }
`;

type StyleProps = {
  display: string;
};

interface Props {
  month: Month;
  index: number;
}

function TransactionCard({ month, index }: Props) {
  const { token } = useAuth();
  const [show, setShow] = useState(index == 0);

  const { setLastUpdateTime } = useDashboard();

  const sendDeclaration = () => {};

  const toggleShowCard = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const deleteTransaction = (id: number) => {
    if (confirm("Delete transaction?")) {
      if (token) {
        axiosInstance.delete("/transactions/" + id.toString()).then(() => {
          setLastUpdateTime(Date.now());
        });
      }
    }
  };

  const copyToClipboard = (value: number) => {
    navigator.clipboard.writeText(value.toString());
    toast.success("Copied!");
  }

  return (
    <Card>
      <TransactionCardContainer>
        <Title onClick={() => toggleShowCard()}>
          {Months[month.month as keyof typeof Months]}
        </Title>
        <TransactionsContainer display={show ? "flex" : "none"}>
          {month.transactions.map((transaction) => {
            return (
              <Transaction
                key={transaction.id}
                date={transaction.date}
                amount={transaction.amount}
                gelAmount={transaction.gelAmount}
                id={transaction.id}
                onClick={deleteTransaction}
              />
            );
          })}
        </TransactionsContainer>
        <TotalContainer>
          <TotalRow>
            <Text>Total year</Text>
            <Total>
              <TotalAmount>{month.totals.yearGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.yearGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <TotalRow>
            <Text>Total month</Text>
            <Total>
              <TotalAmount>{month.totals.monthGelAmount} ლ</TotalAmount>
              <CopyButton
                onClick={() => copyToClipboard(month.totals.monthGelAmount)}
              >
                <CopyIcon></CopyIcon>
              </CopyButton>
            </Total>
          </TotalRow>
          <Button
            display={show ? "none" : "none"}
            text="Send Declaration"
            onClick={sendDeclaration}
          />
        </TotalContainer>
      </TransactionCardContainer>
    </Card>
  );
}

export default TransactionCard;
