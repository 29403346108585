import React from "react";
import styled from "styled-components";
import AuthorizationInput from "../../shared/inputs/AuthorizationInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 22px;
`;

interface Props {
  email: string;
  password: string;
  error: boolean;
  onChangeEmail: (e: string) => void;
  onChangePassword: (e: string) => void;
}

function SignUpInputGroup({
  email,
  password,
  onChangeEmail,
  onChangePassword,
  error,
}: Props) {
  return (
    <Container>
      <AuthorizationInput
        secure={false}
        placeholder="Email address"
        value={email}
        onChange={onChangeEmail}
        error={error}
      />
      <AuthorizationInput
        secure={true}
        placeholder="Enter your password"
        value={password}
        onChange={onChangePassword}
        error={error}
      />
    </Container>
  );
}

export default SignUpInputGroup;
