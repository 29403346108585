import axiosInstance from "../../../../helpers/axiosInstance";

export const saveTransactionAction = (
  form: DashboardTransactionForm
) => {
  return axiosInstance.post(
    "/transactions",
    {
      transaction: {
        amount: form.amount,
        amount_currency: form.currency,
        date: form.date,
      },
    }
  );
};
