import React from "react";
import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  width: 100%;
  margin-top: 80px;
  padding: 19px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  cursor: pointer;
  border-radius: 35px;
  border: none;
  color: var(--color-White);
  background: var(--color-Black);
  -webkit-tap-highlight-color: transparent;
  &:active {
    margin-top: 84px;
    margin-bottom: -4px;
  }
`;

interface Props {
  text: string;
}

function AuthorizationButton({ text }: Props) {
  return <Button type="submit">{text}</Button>;
}

export default AuthorizationButton;
