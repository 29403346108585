import React from "react";
import styled from "styled-components";
import { useAuth } from "../../hooks/useAuth";
import DropdownHeader from "../pages/home/DropdownHeader";

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  margin-top: 17px;
  width: 100%;
  height: 26px;
  border-radius: 30px;
`;

const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--color-Black);
  text-align: left;
  text-transform: uppercase;
  padding-left: 16px;
  user-select: none;
`;

const PremiumStatus = styled.p`
  font-size: 12px;
  margin-left: 8px;
  color: var(--color-Black);
  font-weight: 400;
  font-size: 12px;
`;

const LeftGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

function Header() {
  const { profile } = useAuth();

  return (
    <HeaderContainer>
      <LeftGroup>
        <Title>my income</Title>
        {profile && profile.premium.active && (
          <PremiumStatus>
            💛&nbsp;&nbsp;premium until {profile.premium.until}
          </PremiumStatus>
        )}
      </LeftGroup>
      <DropdownHeader />
    </HeaderContainer>
  );
}

export default Header;
