import React, { useState } from "react";
import styled from "styled-components";
import SignUpInputGroup from "./SignUpInputGroup";
import SignUpCheckbox from "./SignUpCheckbox";
import AuthorizationButton from "../../shared/buttons/AuthorizationButton";
import { useAuth } from "../../../hooks/useAuth";
import { NavLink, useNavigate } from "react-router-dom";
import { signUpAction } from "./actions/signUpAction";
import InputErrorMessage from "../../shared/inputs/InputErrorMessage";

const Container = styled.form`
  position: relative;
  margin: auto;
  width: 90%;
`;

const InputErrorMessageWrapper = styled.div`
  margin-top: 30px;
`;

const Title = styled.h1`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-Black);
`;

const TextLink = styled.p`
  margin-top: 60px;
  padding-bottom: 50px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Black);
`;

const Link = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--color-Pink400);
`;

function SignUpBody() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const { setToken } = useAuth();
  const navigate = useNavigate();

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setError(null);
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    setError(null);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    signUpAction({ email, password })
      .then((res) => {
        const { headers } = res;
        setToken(headers["authorization"]);
        localStorage.setItem("token", headers["authorization"]);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.status.message);
      });
  };

  return (
    <Container onSubmit={(e) => onSubmit(e)}>
      <Title>Sign up</Title>
      <SignUpInputGroup
        email={email}
        password={password}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        error={!!error}
      />
      <SignUpCheckbox />
      <InputErrorMessageWrapper>
        <InputErrorMessage message={error} />
      </InputErrorMessageWrapper>
      <AuthorizationButton text="Create account" />
      <TextLink>
        Already registered? <Link to="/login">Login</Link>
      </TextLink>
    </Container>
  );
}

export default SignUpBody;
