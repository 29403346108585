import React from "react";
import styled from "styled-components";
import LoginBody from "./LoginBody";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
  background-color: var(--color-White);
`;

function LoginPage() {
  return (
    <Page>
      <LoginBody />
    </Page>
  );
}

export default LoginPage;
