import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../node_modules/modern-normalize/modern-normalize.css";
import { createGlobalStyle } from "styled-components";
import App from "./App";

const Global = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

div#app {
  height: 100%
}

html {
  height: 100%;
}

body {
  height: 100%;
}
`;

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register(new URL("./serviceWorker.js", import.meta.url))
      .then((registration) => console.log("Service worker registered"))
      .catch((error) =>
        console.error("Error registering service worker", error)
      );
  });
}

const root = ReactDOM.createRoot(document.getElementById("app") as HTMLElement);
root.render(
  <>
    <Global />
    <App />
  </>
);
