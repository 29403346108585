import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../../../hooks/useAuth";
import { Link } from "react-router-dom";
import MenuSVG from "jsx:../../../assets/Menu.svg";
import axiosInstance from "../../../helpers/axiosInstance";

const MenuContainer = styled.div`
  padding: 0 16px;
`;

const Menu = styled.div<StyledProps>`
  margin-top: 36px;
  display: ${(props) => props.display};
  padding: 24px 24px;
  row-gap: 8px;
  border-radius: 12px;
  background-color: var(--color-White);
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  right: 16px;
  border: 2px solid var(--color-UltraLightGray);
`;

const MenuButton = styled.button`
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: none;
  border: none;
  height: 24px;
  -webkit-tap-highlight-color: transparent;
  transform: rotate(180deg);
`;

const MenuItemLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  color: var(--color-Black);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  -webkit-tap-highlight-color: transparent;
  &:active {
    color: var(--color-Gray);
  }
`;

const LogoutButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: var(--color-Black);
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: right;
  display: flex;
  -webkit-tap-highlight-color: transparent;
  &:active {
    color: var(--color-Gray);
  }
`;

type StyledProps = {
  display: string;
};

function DropdownHeader() {
  const { token, profile, removeToken } = useAuth();
  const [show, setShow] = useState(false);
  const [buyPremiumLink, setBuyPremiumLink] = useState<string | null>(null);
  const [manageSubscriptionLink, setManageSubscriptionLink] = useState<
    string | null
  >(null);
  const drop = useRef<HTMLButtonElement | null>(null);

  const logoutOnClick = () => {
    if (token) {
      axiosInstance.delete("/logout").then(() => {
        removeToken();
      });
    }
  };

  const toggleShowSettings = () => {
    if (show == false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    const onClick = ({ target }: MouseEvent) => {
      if (drop.current) {
        drop.current.contains(target as Node) || setShow(false);
      }
    };

    document.addEventListener("click", onClick);

    if (profile && !profile.premium.active) {
      setBuyPremiumLink(
        `${process.env.REACT_APP_PREMIUM_BASE_URL}?checkout[custom][user_id]=${profile.id}`
      );
    }

    if (profile && profile.premium.active) {
      setManageSubscriptionLink(profile.links.manageSubscription);
    }

    return () => document.removeEventListener("click", onClick);
  }, [profile]);

  return (
    <MenuContainer>
      <Menu display={show ? "flex" : "none"}>
        <MenuItemLink to="/">Home</MenuItemLink>
        {buyPremiumLink && (
          <MenuItemLink to={buyPremiumLink}>⭐ Buy Premium</MenuItemLink>
        )}
        {manageSubscriptionLink && (
          <MenuItemLink to={manageSubscriptionLink} target="_blank">
            Manage Subscription
          </MenuItemLink>
        )}
        <MenuItemLink to="/notifications">Notifications</MenuItemLink>
        <MenuItemLink to="/settings">Settings</MenuItemLink>
        <LogoutButton onClick={() => logoutOnClick()}>Logout</LogoutButton>
      </Menu>
      <MenuButton ref={drop} onClick={() => toggleShowSettings()}>
        <MenuSVG />
      </MenuButton>
    </MenuContainer>
  );
}

export default DropdownHeader;
