import React, { useState } from "react";
import styled from "styled-components";
import AuthorizationInput from "../../shared/inputs/AuthorizationInput";
import AuthorizationButton from "../../shared/buttons/AuthorizationButton";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../helpers/axiosInstance";
import InputTip from "../../shared/InputTip";

const Body = styled.div`
  position: relative;
  margin: auto;
  width: 90%;
`;

const Title = styled.h1`
  margin-bottom: 60px;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--color-Black);
`;

const LinksContainer = styled.div`
  margin-top: 60px;
`;

const TextLink = styled.p`
  padding-bottom: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--color-Black);
`;

const Link = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--color-Pink400);
`;

const Message = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
  color: var(--color-DarkGray);
`;

const EmailSentContainer = styled.div`
`;

const EmailSentFooter = styled.div`
  margin-top: 60px;
`;

const EmailWithinMessage = styled.span`
  font-weight: 700;
`;

function RecoveryPasswordBody() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);

  const onChangeEmail = (e: string) => {
    setEmail(e);
    setError(null);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required");
      return;
    }

    axiosInstance.post("/restore_password", {
      email: email,
    }).then(() => {
      setEmailSent(true);
    })
  };

  return (
    <Body>
      {emailSent && <Title>Check your email</Title>}
      {!emailSent && <Title>Restore password</Title>}

      {emailSent && (
        <EmailSentContainer>
          <Message>
            <p>
              Email has been sent to
              {<EmailWithinMessage> {email}</EmailWithinMessage>}
            </p>
            <p>Please check your inbox and follow the instructions</p>
          </Message>
          <EmailSentFooter>
            <Link to="/login">Back to login</Link>
          </EmailSentFooter>
        </EmailSentContainer>
      )}

      {!emailSent && (
        <>
          <form onSubmit={(e) => onSubmit(e)}>
            <AuthorizationInput
              secure={false}
              placeholder="Email address"
              value={email}
              onChange={onChangeEmail}
              error={!!error}
            />
            <InputTip>
              Enter your email. A link to change your password will be sent to
              you.
            </InputTip>
            <AuthorizationButton text="Send" />
          </form>
          <LinksContainer>
            <TextLink>
              Don't Have An Account? <Link to="/sign-up">Sign up</Link>
            </TextLink>
            <TextLink>
              Already registered? <Link to="/login">Login</Link>
            </TextLink>
          </LinksContainer>
        </>
      )}
    </Body>
  );
}

export default RecoveryPasswordBody;
